import React, { useState } from "react";
import { FaPlus, FaTrashAlt, FaThLarge, FaBars } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"; // Import Drag and Drop
import axios from "axios"; // Assuming you use axios for API requests
import "./EditTvpayouts.css"; // Assuming CSS is imported
import { Link } from "react-router-dom";
import backarrow from "../../components/Images/backarrow.svg";
import ReactPlayer from "react-player";

function EditTvpayouts() {

    const [liveDestination, setLiveDestination] = useState("0"); // default "Choose an Option"
    const [advanced, setadvanced] = useState(false); // checkbox state

    // Handle select dropdown change
    const handleSelectChangelive_destination = async (e) => {
        const value = e.target.value;
        setLiveDestination(value);

        try {
            await axios.post("/api/update-destination", { live_destination: value });
            console.log(`Live Destination updated to ${value}`);
        } catch (error) {
            console.error("Error updating live destination", error);
        }
    };

    // Handle advanced checkbox toggle
    const handleToggleChangeadvanced = async () => {
        // Check if a valid option is selected
        if (liveDestination !== "0") {
            const newValue = !advanced;
            setadvanced(newValue);

            try {
                await axios.post("/api/update-advanced", { advanced: newValue ? 1 : 0 });
                console.log(`advanced updated to ${newValue ? 1 : 0}`);
            } catch (error) {
                console.error("Error updating advanced", error);
            }
        } else {
            console.log("Please select a valid destination before toggling.");
        }
    };

    // Handle delete button to reset the select dropdown
    const handleDeleteadvanced = async () => {
        setLiveDestination("0");
        setadvanced(false); // Reset the checkbox state when destination is deleted

        try {
            await axios.post("/api/reset-destination");
            console.log("Live Destination reset to 0");
        } catch (error) {
            console.error("Error resetting live destination", error);
        }
    };

    return (
        <div className="m-0 p-0">


            <Link to="/tv-payouts" className="backarrow py-4">
                <img src={backarrow} alt="flogo" width={20} height={20} />{" "}
                <span> Back</span>
            </Link>

            <section className="container-fluid iq-card mt-2">
                <div className="row py-3 col-lg-12">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                        <div className="shadow-sm p-2 bg-body">
                            <label className="m-0">Duration</label>
                            <div className="row mt-2 text-start">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <select
                                            className="form-select"
                                            id="type"
                                            name="type"
                                            size="lg"
                                        >
                                            <option value="0">Choose an Option</option>
                                            <option value="1">Schedule Payouts</option>
                                            <option value="2">Loop Payouts</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 text-start">
                                <div className="col-12">
                                    <div className="mt-2">
                                        <input
                                            type="datetime-local"
                                            name="date"
                                            id="date"
                                            className="rs-input form-control-lg"
                                            placeholder="Year"
                                            style={{ height: "50px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row mt-2 text-start">
                                <ReactPlayer
                                    url="https:\/\/bitdash-a.akamaihd.net\/content\/sintel\/hls\/playlist.m3u8"
                                    controls={true} // Enable player controls
                                    width="100%"
                                    height="150px"
                                // config={{
                                //     file: {
                                //         attributes: {
                                //             poster: editUser?.Player_thumbnail
                                //         }
                                //     }
                                // }}
                                />
                            </div>
                        </div>
                        <div className="mt-4 shadow-sm p-1 bg-body">
                            <ul className="nav nav-pills mb-3 justify-content-evenly" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link active"
                                        id="pills-home-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-home"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-home"
                                        aria-selected="true"
                                    >
                                        Destination
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="pills-profile-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-profile"
                                        aria-selected="false"
                                    >
                                        Advanced Setting
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div>
                                        <div className="d-flex row text-start">
                                            <div className="col-12">
                                                <div>
                                                    <select
                                                        className="form-select"
                                                        id="live_destination"
                                                        name="live_destination"
                                                        value={liveDestination}
                                                        onChange={handleSelectChangelive_destination}
                                                        size="lg"
                                                    >
                                                        <option value="0">Choose an Option</option>
                                                        <option value="1">Live Stream Video 1</option>
                                                        <option value="2">Live Stream Video 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 text-center">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <label className="switch">
                                                        <input
                                                            name="advanced"
                                                            id="advanced"
                                                            type="checkbox"
                                                            checked={advanced}
                                                            onChange={handleToggleChangeadvanced}
                                                            className="rs-input"
                                                            disabled={liveDestination === "0"} // Disable if no valid option is selected
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <button className="btn btn-outline-danger" onClick={handleDeleteadvanced}>
                                                        <FaTrashAlt />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    Advanced Setting
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                        <div>
                            <h6>Schedule</h6>
                            <hr></hr>

                            <div className="row col-lg-12">
                                <div className="col-12 col-sm-7 col-md-7 col-lg-7">
                                    <div className="d-flex justify-content-between flex-nowrap">

                                        <div className="row col-lg-12 align-items-center">
                                            <div className="col-12 col-sm-7 col-md-7 col-lg-7">

                                                <lable>Adjust the Start Time for the contents.</lable>
                                            </div>
                                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 text-end">

                                                <label className="switch">
                                                    <input
                                                        name="featured"
                                                        id="featured"
                                                        // onChange={handleInputenable}
                                                        className="rs-input"
                                                        // defaultChecked={
                                                        //     editUser?.featured === 1 ? true : false
                                                        // }
                                                        // checked={editUser?.featured === 1 ? true : false}
                                                        type="checkbox"
                                                    />
                                                    <span
                                                        className="slider round"
                                                        name="featured"
                                                        id="featured"
                                                    // onChange={handleInputenable}
                                                    // value={editUser?.featured === 1 ? "1" : "0"}
                                                    ></span>
                                                </label>

                                            </div>
                                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 text-start">
                                                <lable>Retain Contents</lable>

                                            </div>

                                        </div>




                                    </div>
                                </div>
                                <div className="col-12 col-sm-5 col-md-5 col-lg-5">hello</div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default EditTvpayouts;
