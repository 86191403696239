import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./AddUser.css";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import AdminSaveHeader from "../../../components/CommonComponents/AdminSaveHeader";
import FormContainer from "../../../components/CommonComponents/FormContainer";
import LeftWrapper from "../../../components/CommonComponents/LeftWrapper";
import Wrapper from "../../../components/CommonComponents/Wrapper";
import MessageHeading from "../../../components/CommonComponents/MessageHeading";
import MessageLabel from "../../../components/CommonComponents/MessageLabel";
import MessageBox from "../../../components/CommonComponents/MessageBox";
import { TbEyeClosed } from "react-icons/tb";
import { VscEye } from "react-icons/vsc";
import { RiLockPasswordLine } from "react-icons/ri";
import { getItemToken } from "../../../Utils/localStorageUtils";
import RightWrapper from "../../../components/CommonComponents/RightWrapper";
import { IoClose } from "react-icons/io5";
import AdminCustomButton from "../../../components/CommonComponents/AdminCustomButton";
import { toast } from "react-toastify";

function AddUser() {
  const { id } = useParams()
  const navigate = useNavigate();

  const nameRef = useRef(null)
  const lastnameRef = useRef(null)
  const emailRef = useRef(null)
  const profileRef = useRef(null)
  const passwordRef = useRef(null)
  const mobileRef = useRef(null)
  const codeRef = useRef(null)
  const roleRef = useRef(null)

  const userAccessToken = getItemToken('access_token');

  const [profileImageFile, setProfileImageFile] = useState('');
  const [editUserError, setEditUserError] = useState({})
  const [passwordType, setPasswordType] = useState("password");
  const [showOverlay, setShowOverlay] = useState(false);
  const [domReload, setDomReload] = useState(false)
  const [defaultData, setDefaultData] = useState([])
  const [editUser, setEditUser] = useState({
    username: "",
    last_name: "",
    email: "",
    password: "",
    mobile_number: "",
    role: "",
    ccode: "",
    active: 0
  });

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleInput = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === 'checkbox') {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: checked ? 1 : 0,
      }));
    } else {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [name]: value,
      }));
    }
    setEditUserError((prevEditUserError) => ({
      ...prevEditUserError,
      [name]: '',
    }));
  };

  const fetchData = async () => {
    await axios.get(`${process.env.REACT_APP_Baseurl}/admin/users/create`, { headers: userAccessToken, })
      .then((response) => {
        setDefaultData((prev) => ({
          ...prev,
          role: response?.data?.role,
          country: response?.data?.country
        }))
      })
      .catch((error) => console.log(error));
  }
  const getUser = async () => {
    setShowOverlay(true)
    const reqData = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/users/edit/${id}`, { headers: userAccessToken });
    if (reqData?.data?.status) {
      setShowOverlay(false)
      setEditUser(reqData?.data?.users[0]);
      setEditUser(prevState => {
        const { mobile, ...rest } = prevState;
        return { ...rest, mobile_number: mobile,password:"*****"}
      });
    }
  };


  const handleImageChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImageFile(selectedFile);
      };
      reader.readAsDataURL(selectedFile);
    }
    setEditUserError((editUserError) => ({
      ...editUserError,
      'image': '',
    }));
  };

  const formValidation = () => {
    let formIsValid = true;
    if (!editUser?.username) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        username: "User Name cannot be empty.",
      }));
      nameRef.current.focus();
      formIsValid = false;
    }

    if (!editUser?.last_name) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        last_name: "Last Name cannot be empty.",
      }));
      lastnameRef.current.focus();
      formIsValid = false;
    }

    if (!editUser.email) {
      setEditUserError((prevFormData) => ({ ...prevFormData, email: "Email cannot be empty" }));
      emailRef.current.focus();
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(editUser.email)) {
      setEditUserError((prevFormData) => ({ ...prevFormData, email: "Invalid email address" }));
      emailRef.current.focus();
      formIsValid = false;
    } else {
      setEditUserError((prevFormData) => ({ ...prevFormData, email: "", }));
    }
    if (!id && !editUser?.password) {
      setEditUserError(prevFormData => ({
        ...prevFormData,
        password: "Passwords cannot be empty.",
      }));
      passwordRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.ccode) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        ccode: "Code cannot be empty.",
      }));
      codeRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.mobile_number) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        mobile_number: "Mobile Number cannot be empty.",
      }));
      mobileRef.current.focus();
      formIsValid = false;
    }
    if (!editUser?.role) {
      setEditUserError((prevFormData) => ({
        ...prevFormData,
        role: "User Role cannot be empty.",
      }));
      roleRef.current.focus();
      formIsValid = false;
    }
    if (!id) {
      if (!profileImageFile) {
        setEditUserError((prevFormData) => ({
          ...prevFormData,
          image: "Please provide profile image",
        }));
        profileRef.current.focus();
        formIsValid = false;
      }
    }
    return formIsValid
  }

  const handleImageUpload = async (userId) => {
    const imageFormData = new FormData();
    imageFormData.append("user_id", userId);
    imageFormData.append("avatar", profileImageFile);
    if (profileImageFile) {
      await axios.post(`${process.env.REACT_APP_Baseurl}/admin/users/avatar-update`, imageFormData, { headers: userAccessToken });
    }
  };

  const handleSubmit = async () => {
    setShowOverlay(true)
    try {
      const formData = new FormData();
      for (const key in editUser) {
        formData.append(key, editUser[key]);
      }
      const res = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/users/store`, formData, { headers: userAccessToken })
      if (res?.data?.status) {
        handleImageUpload(res?.data?.users.id)
        toast.success(res?.data?.message)
        setShowOverlay(false)
        navigate("/users");
      }
      else {
        setShowOverlay(false)
        toast.error(res?.data?.message + "  Email Already has been taken!")
      }
    }
    catch (err) {
      toast.error(err?.response?.data?.message)
    }
  }

  const handleUpdate = async () => {
    setShowOverlay(true)
    try {
      const formData = new FormData();
      formData.append("username", editUser?.username);
      formData.append("last_name", editUser?.last_name);
      formData.append("email", editUser?.email);
      formData.append("ccode", editUser?.ccode);
      formData.append("mobile_number", editUser?.mobile_number);
      formData.append("role", editUser?.role);
      formData.append("active", editUser?.active);
      formData.append("password", editUser?.password);
      await axios.post(`${process.env.REACT_APP_Baseurl}/admin/users/update/${id}`, formData, { headers: userAccessToken })
        .then((res) => {
          if (res?.data?.status) {
            handleImageUpload(id)
            toast.success(res?.data?.message)
            setShowOverlay(false)
            setDomReload(!domReload)
            setProfileImageFile(null)
          }
          else {
            toast.error(res?.data?.message + "  Email Already has been taken!")
            setShowOverlay(false)
          }
        }).catch((err) => {
          toast.error(err?.data?.message + "  Email Already has been taken!")
          setShowOverlay(false)
        })
    }
    catch (err) {
      toast.error(err?.data?.message)
      setShowOverlay(false)
    }
  }

  const handleValidate = async (e) => {
    e.preventDefault()
    if (formValidation()) {
      if (!id) {
        handleSubmit()
      }
      else {
        handleUpdate()
      }
    }
    else {
      console.log("Form validation failed ");
    }
  }

  useEffect(async () => {
    fetchData()
    if (id) {
      getUser()
    }
  }, [domReload]);
  return (
    <>
      {showOverlay && <Response_Processing></Response_Processing>}
      <AdminSaveHeader heading={id ? "Edit User" : "Add User"} saveText={id ? "Update User" : "Save User"} saveMethod={handleValidate} type="submit" />
      <form onSubmit={handleValidate}  >
        <FormContainer>
          <LeftWrapper>
            <Wrapper>
              <MessageHeading text={id ? editUser?.name : "User Name"} />
              <MessageLabel text="User Name" className="mandatorySimple" />
              <input type="text" name="username" value={editUser?.username} onChange={handleInput} placeholder="Enter User name" ref={nameRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
              {editUserError?.username && (<MessageBox text={`${editUserError?.username}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text="Last Name" className="mandatorySimple" />
              <input type="text" name="last_name" value={editUser?.last_name} onChange={handleInput} placeholder="Enter Last name" ref={lastnameRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
              {editUserError?.last_name && (<MessageBox text={`${editUserError?.last_name}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text="Enter Email Address" className="mandatorySimple" />
              <input type="email" name="email" value={editUser?.email} onChange={handleInput} placeholder="Enter Email Address" ref={emailRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
              {editUserError?.email && (<MessageBox text={`${editUserError?.email}`} classname='errorred mb-2 d-block' />)}

              <MessageLabel text="Password" className="mandatorySimple" />
              <div className="position-relative">
                <input type={passwordType} name="password" value={editUser?.password} onChange={handleInput} placeholder="Enter Password" ref={passwordRef} className="rs-input py-3 px-5 form-control-lg custom-placeholder theme-text-color  mb-3"  />
                <button onClick={togglePassword} type="button" className="position-absolute passWordIcon bg-transparent">
                  {passwordType == "password" ? <TbEyeClosed className="eyeClosed " /> : <VscEye className="eyeOpen " />}
                </button>
                <RiLockPasswordLine className="lockIcon position-absolute" />
                {editUserError?.password && (<MessageBox text={`${editUserError?.password}`} classname='errorred mb-2 d-block' />)}
              </div>

              <MessageLabel text="User's Mobile" className="mandatorySimple" />
              <div className="d-flex align-items-center justify-content-between">
                <div className="col-4">
                  <select name="ccode" value={editUser?.ccode} onChange={handleInput} className="form-select mb-3 custom-placeholder   border border-1 theme-border-color  theme-text-color" ref={codeRef}>
                    <option value='' className="theme-bg-color theme-text-color" >Select Code</option>
                    {defaultData?.country?.map((item, index) => {
                      return <option value={item?.phonecode} className="theme-bg-color theme-text-color">{item?.country_name}-({item?.phonecode})</option>
                    })}
                  </select>
                  {editUserError?.ccode && (<MessageBox text={`${editUserError?.ccode}`} classname='errorred mb-2 d-block' />)}
                </div>
                <div className="col-8 ms-2">
                  <input type="number" name="mobile_number" value={editUser?.mobile_number} onChange={handleInput} placeholder="Enter Mobile number" ref={mobileRef} className="rs-input py-3 px-3 custom-placeholder theme-text-color border border-1 theme-border-color  mb-3" />
                  {editUserError?.mobile_number && (<MessageBox text={`${editUserError?.mobile_number}`} classname='errorred mb-2 d-block' />)}
                </div>
              </div>
            </Wrapper>
          </LeftWrapper>
          <RightWrapper>
            <Wrapper>
              <MessageHeading text='User Profile Image' />
              {id && <img src={editUser?.user_avatar} className="menuIconImageLoad object-fit-contain rounded-2 " />}
              <div className="mx-0 my-3 row">
                <div className="col-6 ps-0">
                  <div className="imagedrop theme-border-color" >
                    <input type="file" ref={profileRef} onChange={(event) => handleImageChange(event)} accept="image/*" className="opacity-0 w-100 h-100" key={profileImageFile ? profileImageFile : "inputKey"} />
                    <label className="theme-text-color position-absolute"><CameraRetroIcon className=" theme-text-color" /> </label>
                  </div>
                </div>
                <div className="col-6 pe-0">
                  <div>
                    {profileImageFile && (
                      <div className="imagedropcopy overflow-hidden rounded-2 theme-border-color text-end">
                        <div className="row mx-0">
                          <div className="col-10 p-0 ">
                            <img src={URL?.createObjectURL(profileImageFile)} alt="Uploaded" className="thumbnails-upload-images" />
                          </div>
                          <div className="col-2 p-0" style={{ alignSelf: "center" }}>
                            <button onClick={() => setProfileImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn"></IoClose></button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {editUserError?.image && (<MessageBox text={`${editUserError?.image}`} classname='errorred mb-2 d-block' />)}

            </Wrapper>
            <Wrapper>
              <MessageHeading text="Status Settings" />
              <div className="row mx-0">
                <div className="col-9 ps-0">
                  <MessageLabel text="User Active Status " />
                </div>
                <div className="col-3 text-end">
                  <label className="switch">
                    <input name="active" onChange={handleInput} checked={Number(editUser?.active) === 1} type="checkbox" className="rs-input" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </Wrapper>
            <Wrapper>
              <MessageHeading text={"Role"} />
              <MessageLabel text="User Role" className="mandatorySimple" />
              <select name="role" value={editUser?.role} onChange={handleInput} className="form-select mb-3 custom-placeholder   border border-1 theme-border-color  theme-text-color" ref={roleRef}>
                {!id && <option value='' className="theme-bg-color theme-text-color" >Select Role</option>}
                {defaultData?.role?.slice(0, 3).map((item, index) => {
                  return <option key={item?.id} value={item?.id} className="theme-bg-color theme-text-color">{item?.role_name}</option>
                })}
              </select>
              {editUserError?.role && (<MessageBox text={`${editUserError?.role}`} classname='errorred mb-2 d-block' />)}

            </Wrapper>
          </RightWrapper>
        </FormContainer>
        <AdminCustomButton saveMethod={handleValidate} saveText={id ? "Update User" : "Save User"} type='submit' className='d-block ms-auto' />
      </form>
    </>
  );
}
export default AddUser;